<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="屏幕转移"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange"
    >
        <ul v-allow="'account'" v-if="is_org" class="tab-wrap" @click="handleTarget">
            <li :class="['tab', currentTab === 'inner' ? 'target' : '']" data-name="inner">企业内部</li>
            <li :class="['tab', currentTab === 'outer' ? 'target': '']" data-name="outer">其他账号</li>
        </ul>
        <div v-if="currentTab==='inner'">
            <p class="tip">该操作会把所选的{{ terminals.length }}个屏幕转移给其他的组织部门</p>
             <Form class="formValidate" ref="formValidate" :model="formValidate" :rules="ruleValidate"  @submit.native.prevent>
                 <FormItem label="" prop="department_id" key="department_id">
                    <Select ref="department" v-model="formValidate.department_id" style="width:200px" transfer>
                        <Option style="display: none;" :value="departmentInfo.department_id">{{ departmentInfo.title }}</Option>
                        <Tree :data="data" @on-select-change="handleTree($event, 'department')" ></Tree>
                    </Select>
                </FormItem>
             </Form>
        </div>
        <div v-else>
            <p class="tip">该操作将会把所选的{{ terminals.length }}个屏幕转移给其他账号<br>无法撤销，请谨慎操作！</p>
             <Form class="formValidate" ref="formValidate" :model="formValidate" :rules="ruleValidate"  @submit.native.prevent>
                 <FormItem label="" prop="organization_id" key="organization_id">
                     <Input v-model="formValidate.organization_id" clearable placeholder="请输入账号ID" style="width:200px"></Input>
                 </FormItem>
             </Form>
        </div>
        <Checkbox class="checkbox-clear-records" v-model="recordsClearable" @on-change="handleClearRecords">清空转移记录
            <Tooltip content="选中后，将清空转移记录" placement="left">
                <Icon size="19" color="#000" type="md-help-circle" />
            </Tooltip>
        </Checkbox>
        <Checkbox class="checkbox-clear" v-model="clearable" @on-change="handleClear">清空播放计划
            <Tooltip content="选中后，本次的转移将清空已选屏幕的播放计划" placement="left">
                <Icon size="19" color="#000" type="md-help-circle" />
            </Tooltip>
        </Checkbox>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">确定</Button>
        </div>
    </Modal> 
</template>
<script>
    import { tree, login } from '@/mixins/index'

    export default {
        name: "",
        mixins: [tree, login],
        components: {},
        props: {
            show: {
                default: false
            },
            terminals: {
                default: () => {
                    []
                }
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                currentTab: 'inner',
                departmentInfo: {
                    department_id: '',
                    title: ''
                },
                nodeData: {},
                data: [],
                formValidate: {
                    department_id: null,
                    organization_id: ''
                },
                ruleValidate: {
                    department_id: [
                        {
                            required: true, type: 'number', message: '请选择', trigger: 'blur' 
                        }
                    ],
                    organization_id: [
                        {
                            required: true, type: 'string', message: '请输入', trigger: 'blur'
                        }
                    ]
                },
                clearable: false,
                recordsClearable: false
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.department_tree]
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    if (!this.is_org) this.currentTab = 'outer'
                    this.data = this.handleRecursive(this.tree_data, null)
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            handleTarget(e) {
                if (e.target.dataset.name === this.currentTab) return
                this.currentTab = e.target.dataset.name
            },
            cancel() {
                this.departmentInfo =  {
                    department_id: '',
                    title: ''
                }
                this.nodeData = {}
                this.currentTab = 'inner'
                this.clearable = false
                this.recordsClearable = false
                this.$refs.formValidate.resetFields()
                this.$emit('cancel')
            },
            handleCheck() {
                let params = {
                    target_hashid: this.formValidate.organization_id
                }
                return this.$api.get('terminals/check_transfer', { params }).then(({ target_department_id, target_name, target_type, target_type_desc }) => {
                    return { target_department_id, target_name, target_type, target_type_desc }
                })
            },
            async confirm(department_id) {
                let params = {
                        rowid_list: this.terminals,
                        department_id: department_id,
                        need_clear_plans: this.clearable,
                        need_clear_action_logs: this.recordsClearable
                    }

                // if (this.currentTab === 'inner') {
                    // let name = localStorage.getItem('organization_name')
                    // let id = null
                    // await this.getOrganizationList().then(data => {
                    //     let len = data.length
                    //     for (let i = 0; i < len; i++) {
                    //         if (data[i].organization_name === name) {
                    //             id = data[i].organization_id
                    //             break
                    //         }
                    //     }
                    // })
                    // params.organization_id = id
                    // params.department_id = this.formValidate.department_id
                // } else {
                    //
                // }
                this.$api.put('terminals/transfer', params).then(() => {
                    this.$emit('confirm')
                    this.cancel()
                }).finally(() => {
                    this.loading = false
                })
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        if (this.currentTab === 'inner') {
                            this.confirm(this.formValidate.department_id)
                            return
                        }
                        let text = ''
                        if (this.clearable) {
                            text = '并清空屏幕的播放计划'
                        }
                        this.handleCheck().then(({ target_department_id, target_name, target_type_desc }) => {
                            this.$Modal.confirm({
                                title: '提示',
                                closable: true,
                                content:  `<p>您将转移${this.terminals.length}个屏幕到${target_type_desc}<br>${target_name}（ID:${this.formValidate.organization_id}）<p>${text}</p><p>该操作无法撤销！是否确认转移</p>`,
                                onOk: () => {
                                    this.confirm(target_department_id)
                                }
                            })
                        })
                        // this.$api.get(`organizations/${this.formValidate.organization_id}/name`).then(({ name }) => {
                        //     this.$Modal.confirm({
                        //         title: '提示',
                        //         closable: true,
                        //         content:  `<p>您将转移${this.terminals.length}个屏幕到<br>${name}（ID:${this.formValidate.organization_id}）<br>该操作无法撤销！是否确认转移</p>`,
                        //         onOk: () => {
                        //             this.confirm()
                        //         }
                        //     })
                        // })
                    }
                })
            },
            handleClear(e) {
                if (!e) return
                this.$Modal.confirm({
                    content: '<p style="margin: 20px 0;">选中后，本次的转移将清空已选屏幕的播放计划</p>',
                    okText: '确定勾选',
                    cancelText: '暂不勾选',
                    closable: true,
                    onCancel: ()=> {
                        this.clearable = false
                    }
                })
            },
            handleClearRecords(e) {
                if (!e) return
                this.$Modal.confirm({
                    content: '<p style="margin: 20px 0;">选中后，将清空转移记录</p>',
                    okText: '确定勾选',
                    cancelText: '暂不勾选',
                    closable: true,
                    onCancel: ()=> {
                        this.recordsClearable = false
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import '~less/color';
.tab-wrap {
    display: inline-block;
    font-size: 14px;
    .tab {
        cursor: pointer;
        position: relative;
        display: inline-block;
        height: 33px;
        width: 107px;
        line-height: 33px;
        font-size: 400;
        text-align: center;
        background: #ececec;
    }
    .target {
        color: #fff;
        background: @primary-color;
    }
}
.tip {
    margin: 20px 0 10px;
    color: #afb4ba;
}
.checkbox-clear-records {
    position: absolute;
    right: 200px;
    bottom: 56px;
}
.checkbox-clear {
    position: absolute;
    right: 30px;
    bottom: 56px;
}
</style>