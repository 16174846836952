<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="播放计划"
        :mask-closable="false"
        :closable="true"
        :transfer="false"
        width="1200"
    >
        <div class="content-wrap">
            <div class="deviceInfo">
                <div class="img-wrap-block">
                    <div class="img-wrap">
                        <template v-if="group">
                            <img v-if="groupInfo.cover_uri" class="image-center" :src="`/cym/${groupInfo.cover_uri}/thumbnail?size=[206,-1]`">
                        </template>
                        <template v-else>
                            <img v-if="deviceInfo.cover_uri" class="image-center" :src="`/cym/${deviceInfo.cover_uri}/thumbnail?size=[206,-1]`">
                            <div v-if="deviceInfo.state_text" class="device-status">
                                <Badge class="badge" :status="deviceInfo.state === 'online' ? 'success' : 'error'" />
                                <span class="text">{{ deviceInfo.state_text._ }}</span>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="device-info">
                    <template v-if="group">
                        <p class="device-serial line-overflow">分组名称:{{ groupInfo.name }}</p>
                        <p class="device-name line-overflow">屏幕数量:{{ groupInfo.terminal_count }}</p>
                    </template>
                    <template v-else>
                        <p class="device-serial line-overflow">屏幕ID:{{ deviceInfo.serial }}</p>
                        <p class="device-name line-overflow">屏幕名称:{{ deviceInfo.name }}</p>
                    </template>
                </div>
            </div>
            <Button  v-allow="'deploy'" type="primary" icon="ios-add" @click="handlePlaySetting">新建投放</Button>
            <no-data v-show="list.length === 0" text="暂无计划"></no-data>
            <div v-show="list.length > 0" class="plan-section">
                <div class="preview-wrap">
                    <img v-if="previewInfo.uri" class="image-center" :src="`/cym/${previewInfo.uri}/thumbnail?size=[693,-1]`">
                </div>
                <load-more class="side-column" :onRefresh="handleLoadData" :disabled="isLoaded" height="100%">
                    <ul class="plan-wrap">
                        <li class="plan-block" v-for="(item, index) in list" :key="index">
                            <div class="img-wrap pointer" @click="handlePreview(item)">
                                <img class="image-center" :src="`/cym/${item.uri}/thumbnail?size=[362,-1]`">
                            </div>
                            <div class="plan-info">
                                <p class="plan-title line-overflow">{{ item.name }}</p>
                                <div class="date">
                                    <Icon type="ios-calendar-outline" size="16" />
                                    <span v-if="item.plan.date.switch">长期投放</span><span v-else>{{ item.plan.date.start }}&nbsp;-&nbsp;{{ item.plan.date.end }} <span v-if="item.dateLabel" class="label">{{ item.dateLabel }}</span></span>
                                </div>
                                <div>
                                    <Icon type="ios-refresh" size="16" />
                                    <span v-if="item.plan.week.switch">每天重复</span><span v-else>{{ item.plan.week.text }}</span>
                                </div>
                                <div class="time">
                                    <Icon type="ios-time-outline" size="16" />
                                    <span v-if="item.plan.time.switch">全天播放</span><span v-else>{{ item.plan.time.start }}&nbsp;-&nbsp;{{ item.plan.time.end }}&nbsp;<span v-if="item.timeLabel" class="label">{{ item.timeLabel }}</span></span>
                                </div>
                                <Dropdown  v-allow="'deploy'" class="more" trigger="click" placement="bottom-end">
                                    <a href="javascript:void(0)">
                                        <!-- <Icon type="md-more" size="20" ></Icon> -->
                                        <div class="md-more"><Icon class="icon" type="md-more" size="20" /></div>
                                    </a>
                                    <DropdownMenu slot="list">
                                        <DropdownItem @click.native="handlePlan(item)">修改计划</DropdownItem>
                                        <DropdownItem @click.native="handleEdit(item)">编辑节目</DropdownItem>
                                        <DropdownItem @click.native="handleRemove(item)">移除节目</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </li>
                    </ul>
                </load-more>
            </div>
        </div>

        <div slot="footer">
            <Button  v-allow="'deploy'" v-if="total !== 0" type="primary" @click="handleRemoveAll">清空所有节目</Button>
        </div>
        <play-setting
            :show="playSettingModalShow"
            :deviceInfo="deviceInfo"
            :group="group"
            :groupInfo="groupInfo"
            @cancel="handlePlaySettingCancel"
            @confirm="handlePlaySettingConfirm"
        ></play-setting>
        <edit-plan
            :show="editPlanModalShow"
            :group="group"
            :planInfo="planInfo"
            :deviceRowid="deviceInfo.rowid"
            :groupId="groupInfo.rowid"
            @confirm="handleEditPlanConfirm"
            @cancel="handleEditPlanCancel"
        ></edit-plan>
    </Modal>  
</template>
<script>
    import playSetting from './playSetting'
    import editPlan from './editPlan'
    import noData from '@/views/components/noData'
    import loadMore from '@/views/components/loadMore'

    export default {
        name: "",
        mixins: [],
        components: {
            playSetting,
            editPlan,
            noData,
            loadMore
        },
        props: {
            group: {
                default: false
            },
            show: {
                default: false
            },
            deviceInfo: {
                default: () => {
                    return {}
                }
            },
            groupInfo: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                modal: false,
                playSettingModalShow: false,
                editPlanModalShow: false,
                list: [],
                weeksList: [
                    {
                        value: 0,
                        text: '周一'
                    },{
                        value: 1,
                        text: '周二'
                    },{
                        value: 2,
                        text: '周三'
                    },{
                        value: 3,
                        text: '周四'
                    },{
                        value: 4,
                        text: '周五'
                    },{
                        value: 5,
                        text: '周六'
                    },{
                        value: 6,
                        text: '周天'
                    }
                ],
                planInfo: {},
                total: 0,
                current: 1,
                pageSize: 6,
                isLoaded: false,
                previewInfo: {}
            }
        },
        computed: {},
        watch: {
            async show(val) {
                this.modal = val
                if (val) {
                    await this.getData()
                    if (this.list.length) {
                        this.previewInfo = this.list[0]
                    }
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel')
                this.current = 1
                this.list = []
                this.previewInfo = {}
                this.disabled = false
            },
            handlePreview(item) {
                this.previewInfo = item
            },
            handlePlaySetting() {
                this.playSettingModalShow = true
            },
            handlePlaySettingCancel() {
                this.playSettingModalShow = false
            },
            async handlePlaySettingConfirm() {
                this.handlePlaySettingCancel()
                this.list =  []
                this.current = 1
                this.disabled = false
                await this.getData()
                this.previewInfo = this.list[0]
            },
            handleEditPlanConfirm() {
                this.handleEditPlanCancel()
                this.list = []
                this.current = 1
                this.disabled = false
                this.getData()
            },
            handleEditPlanCancel() {
                this.editPlanModalShow = false
            },
            handlePlan(item) {
                this.editPlanModalShow = true
                this.planInfo = item
            },
            handleEdit(item) {
                this.$Modal.confirm({
                    title: '温馨提示',
                    content: '编辑节目，所有已投放该节目的屏幕也将一并改变',
                    okText: '继续编辑节目',
                    cancelText: '取消',
                    onOk: () => {
                        const { href } = this.$router.resolve({
                            name: "programEdit",
                            query: {
                                status: 'edit',
                                rowid: item.resource.rowid
                            }
                        });
                        window.open(href, '_blank')
                    }
                })
            },
            handleRemove(item) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '确定删除吗？',
                    okText: '确定',
                    cancelText: '取消',
                    closable: true,
                    loading: true,
                    onOk: () => {
                        let url = ''
                        if  (this.group) {
                            url = `terminal_groups/${this.groupInfo.rowid}/plans/${item.rowid}`
                        } else {
                            url = `terminals/${this.deviceInfo.rowid}/plans/${item.plan_index}`
                        }
                        this.$api.delete(url).then(()=>{
                            this.$Message.success('成功');
                            this.current = 1
                            this.list = []
                            this.getData().then(() => {
                                if (this.list.length) {
                                    this.previewInfo = this.list[0]
                                } else {
                                    this.previewInfo = {}
                                }
                            })
                        }).finally(() => {
                            this.$Modal.remove();
                        })
                    },
                    onCancel: () => {}
                });
            },
            handleRemoveAll() {
                this.$Modal.confirm({
                    title: '提示',
                    content: '确定删除所有节目吗？',
                    okText: '确定',
                    cancelText: '取消',
                    closable: true,
                    loading: true,
                    onOk: () => {
                        let url = ''
                        if  (this.group) {
                            url = `terminal_groups/${this.groupInfo.rowid}/plans/all`
                        } else {
                            url = `terminals/${this.deviceInfo.rowid}/plans/all`
                        }
                        this.$api.delete(url).then(()=>{
                            this.$Message.success('成功');
                            this.list = []
                            this.current = 1
                            this.disabled = false
                            this.previewInfo = {}
                            this.getData()
                        }).finally(() => {
                            this.$Modal.remove();
                        })
                    }
                });
            },
            async handleLoadData(done) {
                await this.getData()
                done()
            },
            getData() {
                let url = ''
                if (this.group) {
                    url = `terminal_groups/${this.groupInfo.rowid}/plans/search`
                } else {
                    url = `terminals/${this.deviceInfo.rowid}/plans/search`
                }
                let params = {
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                return this.$api.get(url, { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        let len = data.length;
                        for (let k = 0; k < len; k++) {
                            let values = data[k].plan.week.weeks,
                                len1 = values.length,
                                len2 = this.weeksList.length,
                                str = '';
                            for (let i = 0; i < len1; i++) {
                                for (let j = 0; j < len2; j++) {
                                    if (values[i] == this.weeksList[j].value) {
                                        str += this.weeksList[j].text
                                        break;
                                    }
                                }
                                if (i !== len1 - 1) {
                                    str += '、'
                                }
                            }
                            data[k].plan.week.text = str
                            let regTime = /^(\d+):(\d+)/,
                                resultTimeStart = data[k].plan.time.start.match(regTime),
                                resultTimeEnd = data[k].plan.time.end.match(regTime),
                                regDate = /(\d+)-(\d+)$/,
                                resultDateStart = data[k].plan.date.start.match(regDate),
                                resultDateEnd = data[k].plan.date.end.match(regDate);
                            if (!data[k].plan.time.switch) {
                                try {
                                    if (Number(resultTimeStart[1]) > Number(resultTimeEnd[1]) || (Number(resultTimeStart[1]) === Number(resultTimeEnd[1]) && Number(resultTimeStart[2]) > Number(resultTimeEnd[2]))) {
                                        data[k].timeLabel = '跨天'
                                    }
                                } catch {
                                    console.log('非法数据，跨天标签处理失败')
                                }
                            }
                            if (!data[k].plan.date.switch) {
                                try {
                                    if (Number(resultDateStart[1] > Number(resultDateEnd[1]) || (Number(resultDateStart[1]) === Number(resultDateEnd[1])) && Number(resultDateStart[2]) > Number(resultDateEnd[2]))) {
                                        data[k].dateLabel = '跨年'
                                    }

                                } catch {
                                    console.log('非法数据，跨年标签处理失败')
                                }
                            }
                        }
                        if (data.length !== 0) {
                            this.current += 1
                        }
                        this.list.push(...data)
                        if (this.list.length == total_count) {
                            this.isLoaded = true
                        } else {
                            this.isLoaded = false
                        }
                        this.total = total_count
                    }
                })
            },
            nextPage(e) {
                this.current = e
                this.getData()
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import '~less/color';

.content-wrap {
    overflow-x: hidden;
    padding: 0 18px;
    .deviceInfo {
        position: relative;
        margin-bottom: 19px;
        .img-wrap-block {
            display: inline-block;
            vertical-align: top;
            .img-wrap {
                position: relative;
                width: 206px;
                height: 117px;
                .device-status {
                    position: absolute;
                    left: 14px;
                    bottom: 14px;
                    height: 21px;
                    padding: 0 5px;
                    border-radius: 4px;
                    background: #000;
                    color: #fff;
                    font-size: 11px;
                    .text {
                        margin-left: -5px;
                        vertical-align: middle;
                    }
                }
            }
        }
        .device-info {
            display: inline-block;
            margin-left: 16px;
            width: 200px;
            vertical-align: top;
            .device-serial {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
            }
            .device-name {
                margin-top: 5px;
                font-size: 11px;
                line-height: 20px;
            }
        }
    }
    .plan-section {
        margin-top: 39px;
        width: 1132px;
        height: 389px;
        .preview-wrap {
            display: inline-block;
            position: relative;
            margin-right: 25px;
            width: 693px;
            height: 100%;
            background: #cfcfcf;
            vertical-align: top;
        }
        .side-column {
            overflow-x: hidden;
            display: inline-block;
            width: 414px;
            vertical-align: top;
            .plan-wrap {
                width: 414px;
                .plan-block {
                    position: relative;
                    margin: 0 0 10px 0;
                    width: 100%;
                    .img-wrap {
                        position: relative;
                        display: inline-block;
                        margin-right: 20px;
                        width: 178px;
                        height: 100px;
                        background: #cfcfcf;
                        box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
                    }
                    .plan-info {
                        position: relative;
                        display: inline-block;
                        width: 210px;
                        vertical-align: top;
                        .plan-title {
                            margin-bottom: 10px;
                            width: calc(100% - 46px);
                            font-size: 14px;
                            line-height: 27px;
                        }
                        .time {
                            > span {
                                margin-left: 5px;
                                font-size: 14px;
                            }
                        }
                        .time, .date {
                            .label {
                                padding: 0 8px;
                                font-size: 10px;
                                color: #fff;
                                background: @primary-color;
                                border-radius: 20px;
                                font-size: 10px;
                            }
                        }
                        .more {
                            position: absolute;
                            right: 15px;
                            top: 0;
                            .md-more {
                                position: relative;
                                width: 29px;
                                height: 29px;
                                background: rgba(0,0,0,0.4);
                                border-radius: 50%;
                                &:hover {
                                    background: rgba(0,0,0,0.6);
                                }
                                .icon {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    color: #f8fbff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>