<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="修改计划"
        :mask-closable="false"
    >
        <plan-common ref="planCommon" :editPlan="editPlan" @on-change="handlePlanChange" :transfer="true"></plan-common>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal> 
</template>
<script>
    import planCommon from 'views/components/plan'

    export default {
        name: "",
        mixins: [],
        components: {
            planCommon
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            planInfo: {
                default: () => {
                    return {}
                }
            },
            deviceRowid: {
                default: null
            },
            group: {
                default: false
            },
            groupId: {
                default: null
            }
        },
        data () {
            return {
                loading: false,
                modal: false,
                editPlan: {}
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.editPlan = this.planInfo.plan
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.editPlan = {}
                this.$emit('cancel')
                this.$refs['planCommon'].cancel()
            },
            submit() {
                let valid = this.$refs.planCommon.handleSubmit()
                if (valid) {
                    let params = {
                        plan: this.editPlan
                    }
                    this.loading = true
                    let url = ''
                    if (this.group) {
                        url = `terminal_groups/${this.groupId}/plans`
                        params.plan_rowid = this.planInfo.rowid
                    } else {
                        url = `terminals/${this.deviceRowid}/plans/${this.planInfo.plan_index}`
                    }
                    this.$api.put(url, params)
                    .then(() => {
                        this.$emit('confirm')
                        this.cancel()
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.loading = false
                    })
                }
            },
            handlePlanChange(params) {
                this.editPlan = params
            }
        },
        created() {}
    }
</script>
<style scoped>
    
</style>