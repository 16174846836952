<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="开关机设置"
        :mask-closable="false"
        width="750"
        @on-visible-change="handleVisibleChange"
    >
        <ul class="tab-wrap" @click="handleTarget">
            <li :class="['tab', currentTab === 'onOff' ? 'target' : '']" data-name="onOff">立即开关机</li>
            <li :class="['tab', currentTab === 'plan' ? 'target': '']" data-name="plan">计划设置</li>
        </ul>
        <div v-if="currentTab === 'onOff'">
            <Select v-model="onOff" style="width:200px">
                <Option value="on" :key="0">立即开机</Option>
                <Option value="off" :key="1">立即关机</Option>
            </Select>
        </div>
        <div v-else>
            <!-- <Checkbox class="checkbox" v-model="switch1">开启计划</Checkbox> -->
            <Form
                v-if="switch1"
                ref="formValidate"
                class="formValidate"
                :model="formValidate"
                :rules="ruleValidate"
                label-position="top"
            >
                <FormItem prop="weekdays">
                    <CheckboxGroup
                        v-model="formValidate.weekdays"
                    >
                        <Checkbox v-for="(item, index) in weeksList" :label="item.value" :key="index" size="small" border style="margin-bottom: 10px;">{{ item.text }}</Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <FormItem>
                    <Row>
                        <Col span="12">
                            <FormItem prop="begin" label="开机时间">
                                <TimePicker transfer format="HH:mm:ss" type="time" v-model="formValidate.begin" key="begin" :placeholder="$t('common.input')"></TimePicker>
                            </FormItem>

                        </Col>
                        <Col span="12">
                            <FormItem prop="end" label="关机时间">
                                <TimePicker transfer format="HH:mm:ss" v-model="formValidate.end" type="time" :placeholder="$t('common.input')"></TimePicker>
                            </FormItem>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
        </div>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ text }}</Button>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            onOffIds: {
                default: () => {
                    return []
                }
            },
            onRefresh: {
                type: Function,
                default: undefined,
                required: false
            },
            group: {
                default: false
            },
            groupId: {
                default: null
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                onOff: 'on',
                formValidate: {
                    weekdays: [0, 1, 2, 3, 4, 5, 6],
                    begin: '',
                    end: ''
                },
                ruleValidate: {
                    begin: [
                        { required: true, type: 'string', message: '请选择', trigger: 'change' }
                    ],
                    end: [
                        { required: true, type: 'string', message: '请选择', trigger: 'change' }
                    ],
                    weekdays: [
                        { required: true, type: 'array', min: 1, message: '请选择', trigger: 'change' }
                    ]
                },
                weeksList: [
                    {
                        value: 0,
                        text: '周一'
                    },{
                        value: 1,
                        text: '周二'
                    },{
                        value: 2,
                        text: '周三'
                    },{
                        value: 3,
                        text: '周四'
                    },{
                        value: 4,
                        text: '周五'
                    },{
                        value: 5,
                        text: '周六'
                    },{
                        value: 6,
                        text: '周天'
                    }
                ],
                switch1: true,
                currentTab: 'onOff'
            }
        },
        computed: {
            text() {
                if (this.currentTab === 'onOff') {
                    return '确定'
                }
                return '保存'
            }
        },

        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    let url = ''
                    if (this.group) {
                        url = `terminal_groups/${this.groupId}/screen/schedule`
                    } else {
                        let len = this.onOffIds.length,
                            str ='';
                        for (let i = 0; i < len; i++) {
                            str += this.onOffIds[i]
                            if (i !== len -1) {
                                str += ','
                            }
                        }
                        url = `terminals/screen/schedule?rowid_list=${str}`
                        this.$api.get(`terminals/screen/power_state?rowid_list=${str}`).then(({ power_state }) => {
                            this.onOff = power_state
                        })
                    }
                    this.$api.get(url).then(({ week_schedule }) => {
                        // this.switch1 = week_schedule_enable
                        this.formValidate.weekdays = week_schedule.weekdays

                        this.formValidate.begin = week_schedule.begin
                        this.formValidate.end = week_schedule.end
                    })
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            handleLoading() {
                this.loading = false
            },
            cancel() {
                this.$emit('cancel')
                this.switch1 = true
                this.onOff = 'on'
                this.currentTab = 'onOff'
                this.formValidate = {
                    weekdays: [0, 1, 2, 3, 4, 5, 6],
                    begin: '00:00:00',
                    end: '00:00:00'
                }
            },
            submit() {
                let params = {}
                if (this.group) {
                    params.rowid = this.groupId
                } else {
                    params.rowid_list = this.onOffIds
                }
                if (this.currentTab === 'onOff') {
                    params.current_state = this.onOff

                    this.loading = true
                    this.onRefresh(params, this.handleLoading)
                    return
                }
                params.week_schedule_enable = this.switch1
                params.week_schedule = {
                    weekdays: this.formValidate.weekdays,
                    begin: this.formValidate.begin,
                    end: this.formValidate.end
                }
                if (!this.switch1) {
                    this.loading = true
                    this.onRefresh(params, this.handleLoading)
                    return
                }
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.onRefresh(params, this.handleLoading)
                    }
                })
            },
            handleTarget(e) {
                if (e.target.dataset.name === this.currentTab) return
                this.currentTab = e.target.dataset.name
            },
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import '~less/color';
.tab-wrap {
    display: inline-block;
    margin: 0 2px 31px 0;
    font-size: 14px;
    .tab {
        cursor: pointer;
        position: relative;
        display: inline-block;
        height: 33px;
        width: 107px;
        line-height: 33px;
        font-size: 400;
        text-align: center;
        background: #ececec;
    }
    .target {
        color: #fff;
        background: @primary-color;
    }
}
.formValidate {
    margin-top: 20px;
}
.checkbox {
    vertical-align: bottom;
}
</style>