<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="投放设置"
        :width="1200"
        :mask-closable="false"
    >
    <div class="content-wrap">
        <div class="deviceInfo">
            <div class="img-wrap-block">
                <div class="img-wrap">
                    <template v-if="group">
                        <img v-if="groupInfo.cover_uri" class="image-center" :src="`/cym/${groupInfo.cover_uri}/thumbnail?size=[206,-1]`">
                    </template>
                    <template v-else>
                        <img v-if="deviceInfo.cover_uri" class="image-center" :src="`/cym/${deviceInfo.cover_uri}/thumbnail?size=[206,-1]`">
                        <div v-if="deviceInfo.state_text" class="device-status">
                            <Badge class="badge" :status="deviceInfo.state === 'online' ? 'success' : 'error'" />
                            <span class="text">{{ deviceInfo.state_text._ }}</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="device-info">
                <template v-if="group">
                    <p class="device-serial line-overflow">分组名称:{{ groupInfo.name }}</p>
                    <p class="device-name line-overflow">屏幕数量:{{ groupInfo.terminal_count }}</p>
                </template>
                <template v-else>
                    <p class="device-serial line-overflow">屏幕ID:{{ deviceInfo.serial }}</p>
                    <p class="device-name line-overflow">屏幕名称:{{ deviceInfo.name }}</p>
                </template>
            </div>
        </div>
        <div class="tabs">
            <div class="tabPane tabPaneActive">
                选择投放内容（单选）
            </div>
            <div :class="['tabPane', tab==='plan'?'tabPaneActive':'']">设置播放计划</div>
        </div>
        <div v-show="tab==='resource'" class="content-section">
            <Row class="search-bar">
                <Col span="10">
                    <RadioGroup class="radio-group-button" v-model="type" type="button" @on-change="handleType">
                        <Radio label="program">节目</Radio>
                        <!-- <Radio label="template"> 模板</Radio> -->
                        <Radio label="material">素材</Radio>
                    </RadioGroup>
                    <Dropdown v-show="type==='program'" class="dropdown" trigger="click" placement="bottom-start">
                        <Icon v-if="category" class="close" type="ios-close-circle" @click="clearCategoryList" />
                        <Button type="text">
                            产品类型<span v-if="category">:{{ category.text._ }}</span>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="(item, index) in programs_category" :key="index" @click.native="handleCategoryList(item)">{{ item.text._ }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown v-if="is_org" class="dropdown dropdown-structure" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                        <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList()" />
                        <Button type="text">
                            组织架构
                            <div v-if="nodeList.length" class="tree line-overflow">
                                <span>:</span>
                                <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                    <span v-if="index !== nodeList.length - 1">,</span>
                                </span>
                            </div>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col span="14">
                    <Button v-if="uploadable" class="float-right" type="primary" @click="handleUpload">上传素材</Button>
                    <Input v-model="query" class="float-right margin-right" clearable placeholder="输入内容名称搜索" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
            <div class="content-body">
                <no-data v-show="list.length === 0" :text="noData"></no-data>
                <RadioGroup  v-show="list.length > 0" class="content-block-wrap" v-model="selected" >
                    <div class="content-block" v-for="item in list" :key="item.rowid">
                        <div class="img-wrap">
                            <template v-if="type === 'program' && item.program ">
                                <img class="image-center" :src="`/cym/${item.program.cover_uri}/thumbnail?size=[362,-1]`">
                                <span class="category">{{ item.category_text._ }}</span>
                                <div class="edit-program pointer" @click="handleEditProgram(item.rowid)">编辑节目</div>
                            </template>
                            <template v-if="type === 'material' && item.uri">
                                <img :class="['image-center', item.material_type.material_type==='template'?'template-preview-image-bkg':'']" :src="`/cym/${item.uri}/thumbnail?size=[362,-1]`">
                                <span class="image-type">{{ item.material_type.text._ }}</span>
                            </template>
                        </div>
                        <Radio class="radio" :label="item.rowid" @click.native="handleAudio(item)"><span>&nbsp;</span></Radio>
                        <div class="name line-overflow">{{ item.name }}</div>
                    </div>
                </RadioGroup>
                <Page
                    v-show="list.length > 0"
                    class="page"
                    :total="total"
                    :current="current"
                    :page-size="pageSize"
                    show-elevator
                    @on-change="nextPage" />
                <Spin v-show="loadingData" fix size="large"></Spin>
            </div>
        </div>
        <plan-common ref="planCommon" v-show="tab==='plan'"  @on-change="handlePlanChange"></plan-common>
    </div>
        <div slot="footer" class="modal-footer">
            <template v-if="disabled">
                <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
                <Button :disabled="selected ? false : true "  type="primary"  @click="handleNext">下一步</Button>
            </template>
            <template v-else>
                <Button type="default" @click="handlePrevious">上一步</Button>
                <Button type="primary" :loading="loading" @click="submit">立即投放</Button>
            </template>
        </div>
        <upload-modal
            ref="upload"
            :show="uploadModalShow"
            :bucket_token="bucket_token"
            @confirm="handleUploadConfirm"
            @cancel="handleUploadCancel"
            @show="handleUploadModal"
        ></upload-modal>
    </Modal>  
</template>
<script>
    import planCommon from 'views/components/plan'
    import uploadModal from 'views/components/upload'
    import noData from '@/views/components/noData'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            planCommon,
            uploadModal,
            noData
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            deviceInfo: {
                default: () => {
                    return {}
                }
            },
            group: {
                default: false
            },
            groupInfo: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                modal: false,
                disabled: true,
                selected: null,
                loading: false,
                current: 1,
                total: 0,
                pageSize: 12,
                type: 'program',
                query: '',
                uploadModalShow: false,
                list: [],
                tab: 'resource',
                plan: {},
                noData: '暂无节目',
                data: [],
                nodeList: [],//用户操作筛选的组织节点
                treeList: [],//用户要提交的组织节点数据
                tree_data: [],//data的初始数据
                loadingData: false,
                category: null
            }
        },
        computed: {
            bucket_token() {
                if (this.$store.state.default_bucket_tokens.org) {
                    return this.$store.state.default_bucket_tokens.org
                }
                return this.$store.state.default_bucket_tokens.user
            },
            user_tree_data() {
                return [this.$store.state.user_tree]
            },
            up_tree_data() {
                return [this.$store.state.up_tree]
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            programs_category() {
                return this.$store.state.programs_category
            },
            uploadable() {
                if (this.is_org && this.$store.state.rolesList.indexOf('content') === -1) return false
                return true
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    let data = []
                    if (this.type === 'program') {
                        data = this.user_tree_data
                    } else {
                        data = this.up_tree_data
                    }
                    this.tree_data = data
                    this.data  = this.handleRecursive(data)
                    this.handleDefault()
                    this.getData()
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            handleDefault() {
                if (this.deviceInfo.category) {
                    let category = this.programs_category.find((item) => item.key === this.deviceInfo.category)
                    this.category = category
                }
            },
            cancel() {
                this.category = null
                this.query = ''
                this.tab = 'resource'
                this.current = 1
                this.list = []
                this.disabled = true
                this.selected = null
                this.type = 'program'
                this.noData = '暂无节目'
                this.nodeList = []
                this.tree_data = []
                this.$refs['planCommon'].cancel()
                this.$emit('cancel')
            },
            handleNext() {
                this.disabled = !this.disabled
                this.tab = 'plan'
                // this.$refs.planCommon.initTime()
            },
            handlePrevious() {
                this.disabled = !this.disabled
                this.tab = 'resource'
            },
            submit() {
                let valid = this.$refs.planCommon.handleSubmit()
                if (valid) {
                    let params = {
                            resource: {
                                type: this.type,
                                rowid: this.selected
                            },
                            plan: this.plan
                        },
                        url = '';
                    if (this.group) {
                        url = `terminal_groups/${this.groupInfo.rowid}/plans`
                    } else {
                        url = `terminals/${this.deviceInfo.rowid}/plans`
                    }
                    this.loading = true
                    this.$api.post(url, params).then((
                        ) => {
                        this.$emit('confirm')
                        this.cancel()
                        this.$Message(this.$t('message.success'))
                    }).catch(() => {}).finally(() => {
                        this.loading = false
                    })
                }
            },
            handleType(e) {
                this.nodeList = []
                let data = []
                if (e === 'program') {
                    this.noData = '暂无节目'
                    data = this.user_tree_data
                    this.handleDefault()
                } else {
                    this.noData = '暂无素材'
                    data = this.up_tree_data
                }
                this.tree_data = data
                this.data = this.handleRecursive(data)
                this.selected = null
                this.current = 1
                this.getData()
            },
            handleCategoryList(item) {
                this.current = 1
                this.category = item
                this.getData()
            },
            clearCategoryList() {
                this.current = 1
                this.category = null
                this.getData()
            },
            handlePlanChange(params) {
                this.plan = params
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                        query: this.query,
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize
                    },
                    url = '',
                    len = this.nodeList.length;
                if (this.group) {
                    params.mode = this.groupInfo.dir
                } else {
                    params.mode = this.deviceInfo.dir
                }
                if (this.is_org) {
                    if (len) {
                        let bucket_token_list = []
                        this.nodeList.map((item) => {
                            bucket_token_list.push(item.bucket_token)
                        })
                        params.bucket_token_list = JSON.stringify(bucket_token_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.data, 'bucket_token')
                        params.bucket_token_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.bucket_token = this.bucket_token
                }
                switch(this.type) {
                    case 'program':
                        url = 'programs/search';
                        if (this.category) params.category = this.category.key;
                        break;
                    case 'template':
                        url = ''; break;
                    case 'material':
                        url = 'materials/search?add_to_plan_flag=true'; break;
                }
                this.loadingData = true
                this.$api.get(url, { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        this.list = data
                        this.total = total_count
                    }
                }).finally(() => {
                    this.loadingData = false
                })
            },
            handleUpload() {
                if (this.$refs['upload'].defaultList.length > 0) {
                    this.$refs['upload'].loadingModalShow = true
                    return
                }
                this.$el.querySelector('.upload').getElementsByTagName('input')[0].click()
                this.$refs['upload'].init = true
            },
            handleUploadModal () {
                this.uploadModalShow = true
            },
            handleUploadConfirm() {
                this.handleUploadCancel()
                this.nodeList = []
                this.data = this.handleRecursive(this.up_tree_data)
                this.current = 1
                if (this.type !== 'material') {
                    this.selected = null
                    this.type = 'material'
                }
                this.getData()
            },
            handleUploadCancel() {
                this.uploadModalShow = false
            },
            handleAudio() {},
            handleEditProgram(rowid) {
                const { href } = this.$router.resolve({
                    name: "programEdit",
                    query: {
                        status: 'edit',
                        rowid: rowid
                    }
                });
                window.open(href, '_blank')
            },
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";
.content-wrap {
    padding: 0 18px;
    .deviceInfo {
        position: relative;
        margin-bottom: 19px;
        .img-wrap-block {
            display: inline-block;
            vertical-align: top;
            .img-wrap {
                position: relative;
                width: 206px;
                height: 117px;
                .device-status {
                    position: absolute;
                    left: 14px;
                    bottom: 14px;
                    height: 21px;
                    padding: 0 5px;
                    border-radius: 4px;
                    background: #000;
                    color: #fff;
                    font-size: 11px;
                    .text {
                        margin-left: -5px;
                        line-height: 11px;
                    }
                }
            }
        }
        .device-info {
            display: inline-block;
            margin-left: 16px;
            width: 200px;
            vertical-align: top;
            .device-serial {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
            }
            .device-name {
                margin-top: 5px;
                font-size: 11px;
                line-height: 20px;
            }
        }
    }
}
.dir {
    position: relative;
    margin-left: -15px;
    .close {
        display: none;
    }
    &:hover {
        .close {
            display: block;
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
}
.tabs {
    width: 100%;
    margin-bottom: 27px;
    .tabPane {
        position: relative;
        display: inline-block;
        padding-bottom: 15px;
        width: 50%;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        color: #9a9a9a;
        font-weight: 400;
        &::after {
            position: absolute;
            content: '\200B';
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: #ededed;
        }
    }
    .tabPaneActive {
        color: #000;
        &::after {
            background: @primary-color;
        }
    }
}
.content-section {
    line-overflow-x: hidden;
    width: 100%;
    .dropdown {
        position: relative;
        vertical-align: bottom;
        .close {
            display: none;
        }
        .label-mag {
            cursor: pointer;
            padding: 7px 0 7px 4px;
            font-size: 14px;
        }
        &:hover {
            .close {
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
        .tree {
            display: inline-block;
            max-width: 100px;
            vertical-align: bottom;
        }
    }
    .content-body {
        position: relative;
        .content-block-wrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 1140px;
            .content-block {
                position: relative;
                margin: 0 18px 18px 0;
                width: 362px;
                .img-wrap {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    height: 204px;
                    background: #cfcfcf;
                    box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
                    .category {
                        position: absolute;
                        right: 12px;
                        top: 16px;
                        height: 16px;
                        padding: 0 5px;
                        border-radius: 4px;
                        background: #f8fbff;
                        font-size: 12px;
                        text-align: center;
                        line-height: 16px;
                    }
                    .edit-program {
                        opacity: 0;
                        position: absolute;
                        left: 8px;
                        bottom: 10px;
                        width: 100px;
                        height: 29px;
                        background: rgba(0,0,0,0.4);
                        border-radius: 2px;
                        line-height: 29px;
                        color: @background-color;
                        text-align: center;
                        z-index: 1;
                        transition: all .3s ease-in;
                        &:hover {
                            background: rgba(0,0,0,0.6);
                        }
                    }
                    .image-type {
                        position: absolute;
                        right: 12px;
                        top: 16px;
                        padding: 0 5px;
                        height: 16px;
                        border-radius: 4px;
                        background: #f8fbff;
                        font-size: 12px;
                        text-align: center;
                        line-height: 16px;
                    }
                }
                &:hover {
                    .img-wrap {
                        .edit-program {
                            opacity: 1;
                        }
                    }
                }
                .radio {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-right: 0;
                    padding: 5px;
                    width: 100%;
                    height: 100%;
                }
                .name {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
}
</style>
