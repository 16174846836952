<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="修改屏幕信息"
        :mask-closable="false"
    >
        <Form ref="formValidate" class="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem label="屏幕ID">
                {{ editInfo.serial }}
            </FormItem>
            <FormItem label="屏幕名称" prop="name">
                <Input v-model.trim="formValidate.name" placeholder="请输入屏幕名称"></Input>
            </FormItem>
            <FormItem label="屏幕方向" prop="dir">
                <Select v-model="formValidate.dir" placeholder="请选择屏幕方向" @on-select="handleDir" transfer>
                    <Option v-for="(item, index) in dirList" :key="index" :value="item.dir">{{ item.text._ }}</Option>
                </Select>
            </FormItem>
            <template v-if="is_org">
                <FormItem label="控制权" prop="department_id">
                    <Select ref="department" v-model="formValidate.department_id" style="width:200px">
                        <Option style="display: none;" :value="departmentInfo.department_id">{{ departmentInfo.title }}</Option>
                        <Tree :data="data1" @on-select-change="handleTree($event, 'department')" ></Tree>
                    </Select>
                </FormItem>
                <FormItem label="安装位置" prop="address_department_id">
                    <Select ref="address_department" v-model="formValidate.address_department_id" style="width:200px">
                        <Option style="display: none;" :value="addressDepartmentInfo.address_department_id">{{ addressDepartmentInfo.title }}</Option>
                        <Tree :data="data2" @on-select-change="handleTree($event, 'address_department')" ></Tree>
                    </Select>
                </FormItem>
                <FormItem label="" prop="address">
                    <Input v-model.trim="formValidate.address" placeholder="请输入屏幕详细安装地址"></Input>
                </FormItem>
                <FormItem label="管理人员" prop="admin_name">
                    <Input v-model.trim="formValidate.admin_name" placeholder="请输入管理人员"></Input>
                </FormItem>
                <FormItem label="联系电话" prop="admin_phone">
                    <Input v-model="formValidate.admin_phone" placeholder="请输入联系电话"></Input>
                </FormItem>
            </template>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    import { tree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [tree],
        components: {},
        props: {
            show: {
                default: false
            },
            dirList: {
                default: () => {
                    return []
                }
            },
            editInfo: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                loading: false,
                modal: false,
                formValidate: {
                    name: '',
                    dir: '',
                    address: '',
                    admin_name: '',
                    admin_phone: '',
                    department_id: '',
                    address_department_id: ''
                },
                ruleValidate: {
                    name: [
                        { required: true, message: '请输入屏幕名称', trigger: 'blur' },
                        { type: 'string', max: 32, message: '最多32个字符', trigger: 'blur' }
                    ],
                    dir: [
                        { required: true, message: '请选择屏幕方向', trigger: 'blur' }
                    ],
                    admin_phone: [
                        { required: false, type: 'string', validator: (rule, value, callback) => {
                            if (value === '') callback()
                            var regex = /^1[3456789]\d{9}$/
                            if (!regex.test(value)) {
                                callback(new Error('请输入联系电话'))
                            }
                             else {
                                callback()
                            }
                        },  trigger: 'blur' }
                    ],
                    address_department_id: [
                        {
                            required: true, type: 'number', message: '请选择', trigger: 'blur' 
                        }
                    ],
                    department_id: [
                        {
                            required: true, type: 'number', message: '请选择', trigger: 'blur' 
                        }
                    ]
                },
                departmentInfo: {
                    department_id: '',
                    title: ''
                },
                addressDepartmentInfo: {
                    address_department_id: '',
                    title: ''
                },
                data1: [],
                data2: [],
                nodeData: {},
                originDir: null,
                terminal_group_terminal_count: 0,
                terminal_group_name: ''
            }
        },
        computed: {
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            tree_data() {
                return [this.$store.state.department_tree]
            }
        },
        watch: {
            async show(val) {
                this.modal = val
                if (val) {
                    this.originDir = this.editInfo.dir
                    await this.getData(this.editInfo.rowid)

                    if (this.is_org) {
                        this.data1 = this.handleRecursive(this.tree_data, this.formValidate.department_id)
                        this.departmentInfo = {
                            department_id: this.nodeData.department_id || '',
                            title: this.nodeData.title || ''
                        }
                        this.formValidate.department_id = this.nodeData.department_id || ''


                        this.data2 = this.handleRecursive(this.tree_data, this.formValidate.address_department_id)

                        this.addressDepartmentInfo = {
                            address_department_id: this.nodeData.department_id || '',
                            title: this.nodeData.title || ''
                        }
                        this.formValidate.address_department_id = this.nodeData.department_id || ''
                    }

                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.formValidate = {
                    name: '',
                    dir: '',
                    address: '',
                    admin_name: '',
                    admin_phone: '',
                    address_department_id: '',
                    department_id: ''
                }
                this.terminal_group_terminal_count = 0
                this.terminal_group_name = ''
                this.$emit('cancel')
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let params = {
                            name: this.formValidate.name,
                            dir: this.formValidate.dir,
                            department_id: this.formValidate.department_id
                        }
                        if (this.is_org) {
                            params.address_department_id = this.formValidate.address_department_id
                            params.address = this.formValidate.address
                            params.admin_name = this.formValidate.admin_name
                            params.admin_phone = this.formValidate.admin_phone
                        }
                        this.$api.put(`terminals/${this.editInfo.rowid}`, params).then(() => {
                            this.$Message.success(this.$t('message.success'));
                            this.$emit('confirm')
                            this.cancel()
                        }).catch(() => {}).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
            getData(rowid) {
                return this.$api.get(`terminals/${rowid}`).then(({ data }) => {
                    this.formValidate.name = data.name
                    this.formValidate.dir = data.dir
                    this.formValidate.department_id = data.department_id
                    this.terminal_group_terminal_count = data.terminal_group_terminal_count
                    this.terminal_group_name = data.terminal_group_name

                    if (this.is_org) {
                        if (data.address_department_id) {
                            this.formValidate.address_department_id = data.address_department_id
                        }
                        if (data.address) {
                            this.formValidate.address = data.address
                        }
                        if (data.admin_name) {
                            this.formValidate.admin_name = data.admin_name
                        }
                        if (data.admin_phone) {
                            this.formValidate.admin_phone = data.admin_phone
                        }
                    }
                })
            },
            handleDir(e) {
                if (this.originDir === e.value) return
                let content = '修改屏幕方向将清空屏幕的播放列表，确认修改?'
                if (this.terminal_group_terminal_count) {
                    content = `<p>1、您的屏幕已经加入“${this.terminal_group_name}”，修改屏幕方向将会把屏幕移出分组。</p><p>2、修改屏幕方向将清空设备的播放列表。</p>`
                }
                this.$Modal.confirm({
                    title: '提示',
                    content: content,
                    okText: '确定',
                    cancelText: '取消',
                    closable: true,
                    onOk: () => {
                        this.originDir = e.value
                    },
                    onCancel: () => {
                        this.formValidate.dir = this.originDir
                    }
                });
                return false
            }
        },
        created() {}
    }
</script>
<style scoped>
.formValidate {
    width: 60%;
}
</style>