<template>
    <Modal
        class="wrap"
        title="音量"
        v-model="modal"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange"
    >
        <div class="volume-wrapper">
            <div class="progress-wrap" :style="{ 'width': `${width}px` }" @click="handleClick">
                <div class="progress-inner" :style="bgStyle">
                    <div class="drag-block" v-drag="greet" @click.stop="{}">
                        <div class="tip">{{ percent }}</div>
                    </div>
                </div>
            </div>
            
        </div>
        <div slot="footer"></div>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            volumeIds: {
                default: () => {
                    return []
                }
            },
            group: {
                default: false
            },
            groupId: null
        },
        directives: {
            drag: {
                bind(el, binding) {
                    el.onmousedown = function(e) {
                        e.preventDefault();
                        e.stopPropagation();
                        var wrap = el.parentNode.parentNode;
                        //鼠标按下，计算当前元素距离可视区的距离
                        let disX = e.clientX - el.offsetLeft;
                        document.onmousemove = function(e) {
                            //通过事件委托，计算移动的距离
                            let l = e.clientX - disX;
                            //边界处理
                            let w = wrap.offsetWidth
                            if (l > w) {
                                l = w
                            } else if (l < 0) {
                                l = 0
                            }
                            //移动当前元素
                            el.style.left = l + 'px';
                            //将移动距离传出去
                            binding.value({ x: el.offsetLeft, submittable: false })

                        }
                        document.onmouseup = function(e) {
                            //通过事件委托，计算移动的距离
                            let l = e.clientX - disX;
                            //边界处理
                            let w = wrap.offsetWidth
                            if (l > w) {
                                l = w
                            } else if (l < 0) {
                                l = 0
                            }
                            //移动当前元素
                            el.style.left = l + 'px';
                            //将移动距离传出去
                            binding.value({ x: el.offsetLeft, submittable: true })

                            document.onmousemove = null;
                            document.onmouseup = null;
                        }
                    }
                }
            }
        },
        data () {
            return {
                modal: false,
                percent: 0,
                width: 460
            }
        },
        computed: {
            bgStyle() {
                let style = {
                    width: `${this.percent}%`
                }
                return style
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    if (this.group) {
                        this.$api.get(`terminal_groups/${this.groupId}/volume`).then(({ volume }) => {
                            this.percent = volume
                        })
                        return
                    }
                    let params = {
                        rowid_list: JSON.stringify(this.volumeIds)
                    }
                    this.$api.get('terminals/volume', { params }).then(({ volume }) => {
                        this.percent = volume
                    })
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                let block = this.$el.querySelector('.drag-block')
                block.style.left = '100%'
                this.percent = 0
                this.$emit('cancel')
            },
            greet(params) {
                this.percent = parseInt(params.x / this.width * 100)
                if (params.submittable) {
                    this.$emit('confirm', this.percent)
                }
            },
            handleClick(e) {
                let block = this.$el.querySelector('.drag-block')
                block.style.left = '100%'
                this.percent = parseInt(e.offsetX / this.width * 100)
                this.$emit('confirm', this.percent)
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color.less";
.volume-wrapper {
    padding: 50px 0;
    .progress-wrap {
        cursor: pointer;
        margin: 0 auto;
        height: 8px;
        background: #e4e7ed;
        border-radius: 4px;
        .progress-inner {
            cursor: pointer;
            position: relative;
            height: 100%;
            background: @primary-color;
            border-radius: inherit;
            .drag-block {
                cursor: move;
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                border: 2px solid @primary-color;
                border-radius: 50%;
                background: #fff;
                .tip {
                    position: absolute;
                    top: -25px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 36px;
                    height: 19px;
                    background: #989899;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                    text-align: center;
                }
            }
        }
    }
}
</style>